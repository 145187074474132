import React from 'react';
import ChangePasswordCard from '@components/Card/ChangePasswordCard';
import ForgotPasswordCard from '@components/Card/ForgotPasswordCard';
import LoginWithCard from '@components/Card/LoginWithCard';
import ResetPasswordCard from '@components/Card/ResetPasswordCard';
import Layout from '@components/Layout';
import { SignInStatus } from '@enums/signin-status.enum';
import { BaseApi } from '@interfaces/base-api.interface';
import {
  checkVerifiedStatus,
  forgotPassword,
  forgotPasswordChallenge,
  newPasswordChallenge,
  signIn,
} from '@lib/amplify-auth';
import { HttpApi } from '@lib/http.api';
import { Routes } from '@lib/routes';
import { hasUserAnyInterests } from '@lib/utils/utils';
import AppStateContext from '@store/AppContext';
import { useRouter, withRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { ToastOptions, toast } from 'react-toastify';

export function Login() {
  const { locale, replace, query } = useRouter();
  const api: BaseApi = HttpApi.Instance;
  const [showForgotPasswordView, setShowForgotPasswordView] = useState<boolean>(false);
  const [showResetPasswordView, setShowResetPasswordView] = useState<boolean>(false);
  const [showChangePasswordView, setShowChangePasswordView] = useState<boolean>(false);
  let [email, setEmail] = useState<string>('');

  const { updateProfileInfo } = useContext(AppStateContext);
  const intl = useIntl();
  const toastOptions: ToastOptions = {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    progress: undefined,
    theme: 'light',
  };

  const signInCallback = async (username: string, password: string, locale: string) => {
    username = username.trim();
    password = password.trim();
    const result: SignInStatus = await signIn(username, password);
    switch (result) {
      case SignInStatus.SUCCESS:
        await api.signIn(username, password);
        const profile = await api.getProfile();
        if (!hasUserAnyInterests(profile)) {
          replace(Routes.onboarding, Routes.onboarding, {
            shallow: true,
          });
        } else {
          updateProfileInfo(profile);
          replace(`/${locale}${Routes.home}`, `/${locale}${Routes.home}`, {
            shallow: true,
          });
        }
        break;
      case SignInStatus.USER_NOT_VERIFIED:
        const status: boolean = JSON.parse(checkVerifiedStatus());
        if (!status) {
          replace(`/${locale}${Routes.verify}`, `/${locale}${Routes.verify}`, {
            shallow: true,
          });
        }
        break;
      case SignInStatus.NEW_PASSWORD_REQUIRED:
        setShowChangePasswordView(true);
        setEmail(username);
        toast.info(`Your password needs to be changed. Please set a new password`, toastOptions);
        break;
      default:
        toast.error('Incorrect password', toastOptions);
        break;
    }
  };

  const forgotPasswordCallback = async (email: string) => {
    email = email.trim();
    const result = await forgotPassword(email);
    setEmail(email);
    setShowForgotPasswordView(false);
    setShowResetPasswordView(true);
  };

  const resetPasswordCallback = async (verificationCode: string, password: string) => {
    verificationCode = verificationCode.trim();
    password = password.trim();

    const result = await forgotPasswordChallenge(email, verificationCode, password);

    if (result && result.toLocaleLowerCase() == 'success') {
      setEmail('');
      setShowForgotPasswordView(false);
      setShowResetPasswordView(false);
      toast.success('Password reset successfully', toastOptions);
    } else {
      toast.error(
        intl.formatMessage({
          id: 'error.password.reset.toast.message',
        }),
        toastOptions
      );
    }
  };

  const changePasswordCallback = async (oldPassword: string, newPassword: string) => {
    oldPassword = oldPassword.trim();
    newPassword = newPassword.trim();

    const result: boolean = await newPasswordChallenge(email, oldPassword, newPassword);
    if (result) {
      setShowChangePasswordView(false);
      toast.success('Password changed successfully! Please login with your new credentials.', toastOptions);
    } else {
      toast.error('An error occured, please try again', toastOptions);
    }
  };

  useEffect(() => {
    if (query) {
      setEmail(query.email as string);
      setShowResetPasswordView(query.showResetPasswordView === 'true');
    }
  }, [query]);

  return (
    <Layout title="Log In to LEX AI" description="Log In page for the LEX AI platform">
      {!showForgotPasswordView && !showResetPasswordView && !showChangePasswordView && (
        <LoginWithCard
          classNames="min-h-screen"
          submitCallback={(username, password) => signInCallback(username, password, locale)}
          setShowForgotPasswordView={setShowForgotPasswordView}
        />
      )}

      {showForgotPasswordView && (
        <ForgotPasswordCard
          classNames="min-h-screen"
          submitCallback={forgotPasswordCallback}
          setShowForgotPasswordView={setShowForgotPasswordView}
        />
      )}

      {showResetPasswordView && (
        <ResetPasswordCard
          classNames="min-h-screen"
          email={email}
          submitCallback={resetPasswordCallback}
          setShowResetPasswordView={setShowResetPasswordView}
        />
      )}

      {showChangePasswordView && (
        <ChangePasswordCard
          classNames="min-h-screen"
          submitCallback={changePasswordCallback}
          setShowChangePasswordView={setShowChangePasswordView}
        />
      )}
    </Layout>
  );
}

export default withRouter(Login);
